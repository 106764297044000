import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { Text, Div, Tag, Row, Col, Image, Link, Container } from "atomize"
import Footer from "../components/homepage/Footer"

const Payment = () => {
  return (
    <Layout>
      <Header layout="layout-2" />

      <div style={{ height: "36px" }} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Chính sách thanh toán
        </Text>
        <p>
          <b>
            Người mua có thể thanh toán bằng các hình thức thanh toán sau đây và
            được lựa chọn hình thức thanh toán phù hợp:
          </b>
        </p>
        <br />
        <p>Thanh toán bằng chuyển khoản vào tài khoản của công ty</p>
        <br />
        <ul>
          <li>
            Số tài khoản: 0888812346666 tại Ngân hàng TMCP Quân đội (MB Bank)
            <br />
            Người thụ hưởng: Công ty cổ phần công nghệ Ucall
          </li>
          <li>Cổng thanh toán VNPAY</li>
        </ul>
        <br />
        <p>
          Với mỗi phương thức được lựa chọn, sau khi khách hàng thanh toán thành
          công, thông tin sẽ được gửi về bộ phận Tài chính của uCall cho phép
          khách hàng có thể kích hoạt sử dụng gói dịch vụ ngay lập tức.
        </p>
        <br />

        <div style={{ height: "64px" }} />
      </Div>
      <Footer />
    </Layout>
  )
}

export default Payment
